<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="290">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="card-toolbar">
          <a class="btn btn-primary font-weight-bolder">
            {{ $t('MENU.REPORT.PLAN_VIEW.SERVICE_PLAN') }} +</a
          >
        </div>
      </template>
      <v-card>
        <v-card-title class="headline"></v-card-title>
        <v-card-text>
          <v-text-field
            label="Sana (yil)"
            outlined
            dense
            v-model="date"
            maxLength="4"
            minLength="4"
          ></v-text-field
        ></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="newPlainincomes"
            color="green darken-1"
            text
            @click="dialog = false"
            >Yopish</v-btn
          >
          <v-btn
            :disabled="newPlainincomes"
            color="green darken-1"
            text
            @click="submit"
          >
            <i v-if="newPlainincomes" class="el-icon-loading"></i> Yuborish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: '',
      dialog: false,
      newPlainincomes: false
    }
  },
  methods: {
    submit() {
      const data = {
        year: this.date
      }
      console.log(data)
      this.newPlainincomes = true
      this.$store
        .dispatch('createinancePlanIncomeYearly', { data })
        .then(() => {
          this.newPlainincomes = false
          this.dialog = false
        })
        .catch((err) => {
          this.newPlainincomes = false
          console.error(err)
        })
    }
  }
}
</script>

<style></style>
