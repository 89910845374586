<template>
  <div>
    <div class="card p-3">
      <div class="card-button d-flex justify-content-end px-3">
        <create />
      </div>
    </div>
    <div>
      <div class="row">
        <div
          v-for="(item, index) in getData"
          :key="item.id"
          class="col-lg-3 col-xl-3"
        >
          <div class="card">
            <div class="">
              <!-- <div class="p-4">
                <h6 class="m-0">
                  {{ mainItem.plan_year.slice(0, 4) }} yil uchun reja
                </h6>
              </div> -->
            </div>
            <div class="card-body p-0 mb-0">
              <div class="p-1">
                <div style="border: 1px solid #e8e8e8">
                  <div
                    class="cardItem p-5"
                    v-b-toggle="'collapse-' + `${index}-2`"
                  >
                    <div class="m-0 card-ver__line-wrapper">
                      <div class="card-ver__line">
                        <p class="m-0 font-weight-bold">
                          {{ $t('TABLE_HEADER.PLAN_YEAR') }}
                          {{ item.plan_year.slice(0, 4) }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <b-collapse :id="'collapse-' + `${index}-2`">
                    <div
                      class="border p-2 m-1"
                      v-for="item2 in item.month_revenues_plans"
                      :key="item2.id"
                    >
                      <div class="d-flex justify-content-between">
                        <p class="m-0">
                          {{ item2.month_name }}
                        </p>

                        <p class="m-0">
                          <input
                            type="text"
                            @change="amount(item2.id, item2.plan_amount)"
                            style="text-align: right"
                            v-model="item2.plan_amount"
                            v-currency="options"
                          />
                        </p>
                      </div>
                    </div>
                    <div
                      class="
                        border
                        p-2
                        m-1
                        d-flex
                        font-weight-bold
                        justify-content-between
                      "
                    >
                      <p class="m-0">Summa:</p>
                      <p class="m-0">
                        {{ item.total | mask }}
                      </p>
                    </div>
                    <!-- <div class="border p-2 m-1">
                      <p class="m-0">Summa {{ sum }}</p>
                    </div> -->
                  </b-collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import create from './create'
export default {
  data() {
    return {
      page: 1,
      sum: 0,
      date: '',
      total: '',
      summ: '',
      headers: [
        { text: '#', value: 'index' },
        { text: 'Id', value: 'id' },
        { text: 'plan_year', value: 'plan_year' }
      ]
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.REPORT.TITLE') },
      { title: this.$t('MENU.REPORT.PLAN_VIEW.TITLE') },
      { title: this.$t('MENU.REPORT.PLAN_VIEW.SERVICE_PLAN') }
    ])
    await this.$store.dispatch('getFinancePlanIncomeYearly')
  },
  computed: {
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getData() {
      return this.$store.state.requests.financePlanIncomeYearly
    },
    options() {
      return {
        locale: 'zh-ZH',
        currency: [
          this.currencyCode,
          null,
          { prefix: this.prefix, suffix: this.suffix }
        ][this.selectedCurrencyOption],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree
          ? {
              hideNegligibleDecimalDigits: this.hideNegligibleDecimalDigits,
              hideCurrencySymbol: this.hideCurrencySymbol,
              hideGroupingSymbol: this.hideGroupingSymbol
            }
          : false,
        autoDecimalMode: this.autoDecimalMode,
        valueAsInteger: this.valueAsInteger,
        allowNegative: this.allowNegative
      }
    }
  },
  methods: {
    parseFloat(val) {
      if (typeof val == 'string' && Boolean(val)) {
        // console.log(parseFloat(val.replace(/[ ,]/g, ''), 10))/
        return parseFloat(val.replace(/[ ,]/g, ''), 10)
      } else if (typeof val == 'number') return val
      else return 0
      // eslint-disable-next-line no-extra-boolean-cast

      // if (val) return parseFloat(val)
      // else return 0
    },
    amount(id, val) {
      // console.log(id, val)
      this.$store.dispatch('updateinancePlanIncomeYearly', {
        id: id,
        data: { plan_amount: parseFloat(val.replace(/,/g, '')) }
      })
    }
  },
  watch: {
    getData: {
      deep: true,
      handler(val) {
        val.forEach((element) => {
          // total = total + val.plan_amount
          // console.log(element)
          element.total = element.month_revenues_plans
            .map((x) => x.plan_amount)
            .reduce((a, b) => {
              const total = this.parseFloat(a) + this.parseFloat(b)
              return total
            }, 0)
        })
      }
    }
  },
  filters: {
    mask(val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) return val.toLocaleString('zh-ZH')
      else return 0
    }
  },
  components: {
    create
  }
}
</script>

<style scoped>
.card-header {
  padding-top: unset !important;
}
.cardItem:focus {
  outline: none;
  border: 0;
}
</style>
